"use client"

import { useEffect, useRef, type PropsWithChildren } from "react"
import { assignInlineVars } from "@vanilla-extract/dynamic"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import RoundedCta from "~/components/ui/RoundedCta"
import Icon from "~/components/abstracts/Icon"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { Panel, usePanel } from "~/managers/PanelManager"
import { useUSPBannerHeight } from "~/managers/USPBannerManager"

import { px } from "~/styles/utils/px"

import * as css from "./styles.css"

type CartToaster = {
  onClick?: () => void
  onClose?: () => void
}

function CartToaster({ onClick, onClose }: CartToaster) {
  const panelRef = useRef<HTMLDivElement>(null)
  const t = useTranslate()
  const { removeCurrent } = usePanel()
  const uspBannerHeight = useUSPBannerHeight()[0]

  const removePanel = () => {
    onClose?.()
    removeCurrent()
  }

  const handleOnClick = () => {
    onClick?.()
  }

  useEffect(() => {
    panelRef.current?.addEventListener("animationend", removePanel)

    return () => {
      panelRef.current?.removeEventListener("animationend", removePanel)
    }
  }, [])

  return (
    <Panel
      zIndex={50}
      clickOutsideRef={panelRef}
      scrollDisabled={false}
      bgColor="transparent"
      className={css.CartToasterPanel}
      style={assignInlineVars(css.bannerHeightVars, { height: px(uspBannerHeight) })}
    >
      <div className={css.CartToaster} ref={panelRef}>
        <ToasterHead className={css.toasterHead} onClose={removePanel}>
          {t("product_added_to_cart")}
        </ToasterHead>
        <RoundedCta size="largeMobile" width="full" theme="blue-deep" onClick={handleOnClick}>
          {t("cart_view_toaster")}
        </RoundedCta>
      </div>
    </Panel>
  )
}

export default CartToaster

export function ToasterHead({
  className,
  children,
  onClose,
}: PropsWithChildren<PropsWithClassName<{ onClose?(): void }>>) {
  const t = useTranslate()

  return (
    <div className={clsx(className, css.head)}>
      <Icon name="Tick2" width={16} height={16} />
      <p className={css.text}>{children}</p>
      <button className={css.closeButton} aria-label={t("aria_panel_close")} onClick={onClose}>
        <Icon name="Cross" theme="blue-deep" width={20} height={20} />
      </button>
    </div>
  )
}
