import type { ComponentProps } from "react"

import type { Nullish } from "~/@types/generics"
import asText from "~/components/ui/RichText/_data/mock"
import type { SbRichText } from "~/components/ui/RichText/_data/schema"
import Icon from "~/components/abstracts/Icon"
import { Stack } from "~/components/abstracts/Stack"
import type { Dictionary } from "~/providers/I18nProvider/constants"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

type PanelHeaderProps = {
  title: Nullish<string | SbRichText>
  ariaCloseLabel: keyof Dictionary
} & ComponentProps<typeof Stack>

export function PanelHeader({ className, title, ariaCloseLabel, sprinklesCss, ...props }: PanelHeaderProps) {
  const { removeCurrent } = usePanel()
  const t = useTranslate()

  const closePanel = () => {
    removeCurrent()
  }

  return (
    <Stack
      asChild
      sprinklesCss={{
        position: "sticky",
        alignItems: "center",
        justifyContent: "space-between",
        bgColor: { mobile: "grey-bg-page", tablet: "white" },
        pY: 5,
        top: 0,
        zIndex: 20,
        ...(sprinklesCss ?? {}),
      }}
      className={className}
      {...props}
    >
      <header>
        <h3
          className={text({
            design: "spezia-14-18",
            color: "blue-deep",
            weight: "semiBold",
            transform: "uppercase",
            lineHeight: 1,
            letterSpacing: 0.4,
          })}
        >
          {typeof title === "string" ? title : asText(title)}
        </h3>
        <button className={css.ClosePanel} aria-label={t(ariaCloseLabel)} onClick={closePanel}>
          <Icon name="Cross" theme="blue-deep" width={14} height={14} />
        </button>
      </header>
    </Stack>
  )
}
