import type {
  StoryblokRichtextContent,
  StoryblokRichtextContentType,
  StoryblokRichtextMark,
} from "storyblok-rich-text-react-renderer"

import type { Nullish } from "~/@types/generics"
import type { SbRichText } from "~/components/ui/RichText/_data/schema"

interface asRichTextParams {
  type: StoryblokRichtextContentType
  attrs?: StoryblokRichtextContent["attrs"]
  texts: (string | [string, StoryblokRichtextMark])[]
}

export const asRichText = ({ type = "paragraph", attrs = {}, texts }: asRichTextParams): SbRichText => ({
  type: "doc",
  content: texts.map((text) => {
    return {
      type,
      attrs,
      content: [
        {
          type: "text",
          text,
          marks: [],
          content: [],
        },
      ],
    }
  }),
})

const convertNodeToText = (richTextField: SbRichText["content"], separator = " "): string => {
  let result = ""

  for (let i = 0; i < richTextField?.length; i++) {
    const richTextContents = richTextField[i]?.content ?? null

    if (richTextContents) {
      for (let j = 0, k = richTextContents?.length; j < k; j++) {
        const currentRichTextContent = richTextContents?.[j]

        if (currentRichTextContent && "text" in currentRichTextContent) {
          result += (result ? separator : "") + currentRichTextContent?.text
        }
      }
    }
  }

  return result
}

export default function asText(structuredText: Nullish<SbRichText>) {
  if (!structuredText || Object.prototype.toString.call(structuredText?.content) !== "[object Array]") {
    // console.warn(
    //   `Rich text argument should be an Array. Received ${typeof structuredText}`,
    // )
    return null
  }

  return convertNodeToText(structuredText.content)
}

const titleMock = (texts: string[], attrs: StoryblokRichtextContent["attrs"] = {}) =>
  asRichText({ type: "heading", texts, attrs: { level: 1, ...attrs } })
const labelMock = (texts: string[]) => asRichText({ type: "paragraph", texts })
const subtitleMock = (texts: string[]) => asRichText({ type: "paragraph", texts })

const textMock = (texts: string[]) =>
  asRichText({
    type: "paragraph",
    texts,
  }) as SbRichText

const defaultTitleMock = titleMock(["À la plage"])
const defaultLabelMock = labelMock(["Nouvelle collection"])
const defaultSubtitleMock = subtitleMock(["Literie et linge de lit"])
const defaultTextMock = textMock([
  "We all deserve to sleep in good sheets, without having to look for them and without having to spend money. Putting the sheets back at the centre of your nights to rediscover the ultimate pleasure of slipping into them is the mission that Bonsoirs has set itself.",
])

export {
  defaultLabelMock,
  defaultSubtitleMock,
  defaultTextMock,
  defaultTitleMock,
  labelMock,
  subtitleMock,
  textMock,
  titleMock,
}
